$default-icon-size: 22px;
$button-icon-size: 14px;

.anticon {
  font-size: $default-icon-size;
}

.ant-timeline-item .anticon {
  font-size: 16px;
  transform: translateY(-2px);
}

.ant-tag .anticon {
  font-size: 16px;
  transform: translateY(1px);
}

button,
.button-like,
.button-like-icon-size,
.ant-input-suffix {
  .anticon {
    font-size: $button-icon-size;
  }
}

.ant-dropdown-trigger .anticon.anticon-down {
  font-size: $button-icon-size;
  transform: translateY(1px);
}

.ant-dropdown .anticon.anticon-down {
  font-size: $button-icon-size;
}

.ant-select .anticon {
  font-size: $button-icon-size;
}

.ant-input-suffix .anticon {
  color: #d9d9d9;
}

.ant-table-column-sorter-inner {
  .anticon {
    font-size: 11px;
  }
}