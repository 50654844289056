.app-cm1-logo {
  background-image: url('assets/logo.png');
  height: 100%;
  transform: scale(0.8);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  float: left;
  aspect-ratio: 1;
  margin-left: -50px;
}