.card-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(30%, 1fr);
  gap: 10px;
  margin-top: 0;
  height: 85vh;

  @media (max-width: 768px) { 
    .card-container {
      grid-template-columns: repeat(1, 1fr); 
    }
  }
}
body, html {
  margin: 0;
  padding: 0;
}

.ant-layout-content {
  padding: 0 !important;
}
.screen-with-breadcrumb {
  /* background-color: #e4e5e738; */
  background-color: #ffffffff;
  width: 100%;
  height: 100%;
}

.time-filter {
  position: absolute;
  left: 10px; 
  bottom: 10px; 
}

.card {
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  text-align: center;
  display: flex;
  flex-direction: column; 
  justify-content: space-between; 
  position: relative; 
  overflow: hidden; 
}

.card-header {
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 5px 10px;
  border-top-left-radius: 4px; 
  position: absolute;
  top: 0; 
  left: 0; 
  width: 100%; 
  z-index: 2;
}

.leaflet-container {
  flex: 1;
  width: 100%;
  height: 100%; 
  z-index: 1; 
}

.chart-card {
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 35px;
}

.chart-container {
  position: relative;
}

.line-chart {
  position: absolute;
  width: 100%;
  height: 50%;
  top: 0;
  left: 0;
}

.line-chart:nth-child(2) {
  top: 0;
  left: 0;
}

.settings-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 25px; 
  height: 25px; 
  border: 1px solid black; 
  border-radius: 50%; 
  background: transparent;
  padding: 0;
  font-size: 15px; 
  color: black; 
  cursor: pointer;
  margin-left: 5px;
}

.settings-button:hover {
  color: black; 
  border-color: #333; 
}

.settings-button:active {
  color: black; 
  border-color: #666; 
}

.settings-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
}

.settings-button .anticon {
  font-size: inherit; 
  vertical-align: middle;
  margin-top: 2px;
}

.download-positions-button {
  font-size: '12px';
  padding: '3px 5px'; 
}

.show-card-drawer-button {
  position: absolute;
  bottom: 10px;
  right: 5px;
  z-index: 1000;
}

.close-card-drawer-button {
  background-color: #1890ff;
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.about-device-card-row {
  height: 41%
}

.about-device-card-bottom-row {
  height: 41%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}

.about-device-card-col-a {
  width: 40%;
  padding: 5px;
  border-radius: 4px;
  color: #000000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #fff;
}

.about-device-card-col-b {
  width: 100%;
  padding: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #fff;
}

.about-device-card-table {
  border-collapse: collapse;
  border: 1px solid #fff;
  width: 100%;
  height: 100%;
  margin-top: 40px;
}

.breadcumb-header {
  color: 'black';
  margin-left: 25px;
}