.beacon-detail-map-spin {
  position: absolute;
  left: -30;
  top: 6;
}

.beacon-detail-map-spin-no-position {
  position: absolute;
  z-index: 100000;
  left: 1em;
  top: 1em;
}

.info-button {
  background-color: #1890ff;
  color: white;
  border: none;
}

.info-button .anticon {
  font-size: 120%; 
  margin-top: 4px;
}

.beacon-map-card-info-div {
  position: absolute;
  bottom: 10px;
  right: 5px;
  z-index: 1000;
  display: flex;
  gap: 8px;
}

.beacon-map-info-button-popup {
  position: absolute;
  right: 5px;
  background-color: #fff;
  padding: 5px; 
  border: 2px solid #ccc;
  border-radius: 4px;
  z-index: 1000;
  width: 200px; 
  max-width: 450px; 
  text-align: left;
}

.beacon-map-info-button-popup p {
  margin: 2px 0;
  padding: 0; 
}