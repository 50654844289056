.property-label {
  font-weight: bold;
}

.property-value {
  font-weight: lighter;
}

.vertical-form {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;
  grid-row-gap: 1em;

  &.two-columns {
    grid-template-columns: auto 1fr;
  }

  &.four-columns {
    grid-template-columns: auto auto 1fr 1fr;
  }

  .full {
    grid-column: 1/-1;
  }

  span.field, span.label {
    font-weight: bold;
    text-align: start;
    align-self: center;

    &.read-only {
      font-weight: normal;
    }
  }

  span.field {
    height: 30px;
    line-height: 30px;
  }

  span.label {
    margin-bottom: -1em;
  }

  input {
    height: 30px;
  }

  &.hide-numeric-up-downs {
    .ant-input-number-handler-wrap {
      display: none;
    }
  }

  .ant-switch span {
    font-weight: unset;
    text-align: unset;
    align-self: unset;
    margin-bottom: unset;
  }
}
