.canvas-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100vh;
}

.canvas {
  height: 100%;
  width: 100%;
}

.table-header-background {
  background-color: #fafafa;
}

.hidden-row {
  display: none;
}