body .ant-table {
  .ant-table-tbody {
    > tr > td {
      height: 56px;
    }
    > tr:not(.ant-table-expanded-row) > td {
      padding: 0 16px;
    }
  }

  .ant-table-thead {
    > tr:not(.ant-table-expanded-row) > th {
      padding: 12px 16px;
    }
  }

  &.ant-table-middle {
    .ant-table-tbody > tr > td {
      height: 48px;
    }
  }

  &.ant-table-small {
    .ant-table-tbody > tr > td {
      height: 40px;
    }
  }
}
